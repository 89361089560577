window.infoBulle = function(){
    const bulles = document.getElementsByClassName('bulle');
    var bulle = null;
    var handle = null;

    const mouseMove = function(e){
        var limitX = document.documentElement.clientWidth - bulle.offsetWidth;
        var x = e.clientX + window.scrollX;
        var y = e.clientY + window.scrollY - 12;
        var bulleX = (x < limitX) ? (x - 10) : limitX;
        var bulleY = y   - (bulle.offsetHeight - 1) ;
        bulle.setStyle('left', bulleX + 'px').setStyle('top', bulleY + 'px');
        handle.setStyle('left', x + 'px').setStyle('top', y + 'px');
    }

    const pop = function(content){
        bulle = document.createElement('div').addClass('cont-bulle');
        handle = document.createElement('div').addClass('handle-bulle');
        bulle.append(content.cloneNode(true));
        $('body').append(bulle);
        $('body').append(handle);
        document.addEventListener('mousemove', mouseMove);

    }
    for(let i = 0; i < bulles.length; i++){
        var parent = bulles[i].parentElement;
        parent.addEvent('mouseover', function(e){
            pop(bulles[i]);
        });
        parent.addEvent('mouseout', function(e){
            bulle.remove();
            handle.remove();
            document.removeEventListener('mousemove', mouseMove);
        });
    }

}
